import React, {
    useCallback,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'
import openSupport from '@src/utils/jiraHelpDesk'
import consoleService from '@src/services/consoleService'
import AccessDenied from './components/AccessDenied'

function AccessDeniedPage(): JSX.Element {
    const navigate = useNavigate()
    const onBackClick = useCallback(() => {
        navigate('/', {
            replace: true,
        })
    }, [navigate])

    const handleOnSupportClick = useCallback(() => {
        return openSupport((): void => {
            consoleService.error('Jira extension failed to load.')
        })
    }, [])

    return (
        <AccessDenied
            onBackClick={onBackClick}
            handleOnSupportClick={handleOnSupportClick}
        />
    )
}

export default AccessDeniedPage
