import React, {
    useEffect,
} from 'react'
import {
    useParams,
    useNavigate,
} from 'react-router-dom'

import Loading from '@src/shared-components/Loading'
import APP_URLS from '@src/constants/AppUrls'
import APP_FLOW_STEPS from '@src/constants/FlowSteps'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'

const GatewayReceivedByUrl = () => {
    const {
        gateway = '',
    } = useParams()
    const navigate = useNavigate()
    const {
        updateState,
    } = useFlowRouter()

    useEffect(() => {
        if (gateway) {
            navigate(APP_URLS.scanQR, {
                state: {
                    step: APP_FLOW_STEPS.enterEmail,
                    info: {
                        gatewayMac: gateway,
                    },
                },
                replace: true,
            })
        }
    }, [
        gateway,
        navigate,
        updateState,
    ])

    return (
        <Loading isLoading />
    )
}

export default GatewayReceivedByUrl
