import React, {
    useCallback, useState,
} from 'react'

import Typography from '@src/shared-components/Typography'
import useT from '@src/hooks/useT/useT'
import gatewayInstalledIcon from '@src/assets/install_gateway_Illustration.svg'
import InstallGatewayLayout from '@src/shared-components/InstallGatewayLayout'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'
import APP_FLOW_STEPS from '@src/constants/FlowSteps'

import UnsavedChangesPrompt from '@src/shared-components/UnsavedChangesPrompt'
import classes from './InstallGateway.style'

function InstallGateway(): JSX.Element {
    const {
        t,
    } = useT()
    const {
        goToStep,
    } = useFlowRouter()
    const [
        showModal,
        setShowModal,
    ] = useState<boolean>(false)

    const handleNextClick = useCallback(() => {
        goToStep(APP_FLOW_STEPS.takePhotoOverviewEmpty)
    }, [goToStep])

    const handleBackClick = useCallback(() => {
        setShowModal(true)
    }, [])

    const doBackClick = useCallback(() => {
        goToStep(APP_FLOW_STEPS.enterEmail)
    }, [goToStep])

    const handleModalClose = useCallback((positive: boolean) => {
        if (positive) {
            doBackClick()
        } else { setShowModal(false) }
    }, [doBackClick])

    return (
        <>
            <InstallGatewayLayout
                onBackClick={handleBackClick}
                onNextClick={handleNextClick}
                nextButtonText={t('CONFIRM')}
            >
                <center css={classes.header}>
                    <Typography variant="headline5">
                        {t('CHECK_GATEWAY_INSTALLED')}
                    </Typography>
                </center>
                <div
                    css={classes.previewImg}
                    style={{
                        backgroundImage: `url(${gatewayInstalledIcon}`,
                    }}
                />
            </InstallGatewayLayout>
            <UnsavedChangesPrompt
                open={showModal}
                handleClose={handleModalClose}
            />
        </>
    )
}

export default InstallGateway
