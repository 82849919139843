import React, {
    useCallback, useEffect,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'
import queryClient from '@src/services/queryClient'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'

import Summary from './components/Summary'

function SummaryPage(): JSX.Element {
    const navigate = useNavigate()
    const {
        updateState,
        state,
        setIsEdited,
    } = useFlowRouter()

    const handleSubmit = useCallback(() => {
        updateState(() => {
            return {}
        })
        queryClient.resetQueries()
        navigate('/', {
            replace: true,
        })
    }, [
        navigate,
        updateState,
    ])

    useEffect(() => {
        setIsEdited(false)
    }, [setIsEdited])

    return (
        <Summary
            gateway={`${state.gatewayMac}`}
            onSubmit={handleSubmit}
        />
    )
}

export default SummaryPage
