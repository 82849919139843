import React, {
    useCallback, useEffect,
} from 'react'

import consoleService from '@src/services/consoleService'
import openSupport from '@src/utils/jiraHelpDesk'
import APP_FLOW_STEPS from '@src/constants/FlowSteps'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'
import parseGatewayQRCode from './parseGatewayQRCode'
import ScanQR from './components/ScanQR'

function ScanQRPage(): JSX.Element {
    const {
        updateState,
        setIsEdited,
    } = useFlowRouter()

    const handleOnSupportClick = useCallback(() => {
        return openSupport((): void => {
            consoleService.error('Jira extension failed to load.')
        })
    }, [])

    const handleScanSuccess = useCallback(
        (value: string) => {
            const parsedCode = parseGatewayQRCode(value)

            updateState((prev) => {
                return {
                    ...prev,
                    gatewayMac: parsedCode ?? value,
                    allowedToInstall: false,
                }
            }, APP_FLOW_STEPS.enterEmail)
        },
        [updateState],
    )

    useEffect(() => {
        setIsEdited(false)
    }, [setIsEdited])

    return (
        <ScanQR
            onSupportClick={handleOnSupportClick}
            onScanSuccess={handleScanSuccess}
        />
    )
}

export default ScanQRPage
