import ENV from './Env'

const SKYGATE_URL = `${ENV.REACT_APP_SERVER_URL}skygate-backend/v1/`

const API_URLS = {
    checkInstallationPossible: (gatewayImeiMac: string, email: string) => {
        return `${SKYGATE_URL}gateway/${gatewayImeiMac}/flow-information?userEmail=${email}`
    },
    getAttachmentUrl: (gatewayImeiMac: string, email: string) => {
        return `${SKYGATE_URL}gateway/${gatewayImeiMac}/attachment?userEmail=${email}`
    },
    getConfirmInstallationUrl: (gatewayImeiMac: string, email: string) => {
        return `${SKYGATE_URL}gateway/${gatewayImeiMac}/installation-confirmation?userEmail=${email}`
    },
}

export default API_URLS
