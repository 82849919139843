import {
    UseMutationResult, useMutation,
} from '@tanstack/react-query'
import axios from 'axios'

import API_URLS from '@src/constants/ApiUrls'
import useGetAxiosConfig from './useGetAxiosConfig'

export interface IConfirmInstallationRequest {
    latitude: number
    longitude: number
    gatewayImagesPaths?: string[],
}

export interface IConfirmInstallationResponse {
    latitude: number
    longitude: number
}
const useConfirmInstallation = (onSuccess:()=> void): UseMutationResult<
    IConfirmInstallationResponse,
    unknown,
    { gatewayImeiMac: string,
        email: string,
        body: IConfirmInstallationRequest,
    }
> => {
    const config = useGetAxiosConfig()

    return useMutation({
        mutationKey: ['useConfirmInstallation'],
        onSuccess,
        mutationFn: (props) => {
            return axios.post(
                API_URLS.getConfirmInstallationUrl(props.gatewayImeiMac, props.email),
                props.body,
                config,
            )
        },
    })
}

export default useConfirmInstallation
