import React from 'react'

import SummarySvg from '@src/assets/summary.svg'
import Button from '@src/shared-components/Button'
import Layout from '@src/Layout'
import useT from '@src/hooks/useT/useT'
import classes from './Summary.style'

type Props = {
    gateway: string
    onSubmit?: () => void
}

function Summary({
    gateway,
    onSubmit,
}: Props): JSX.Element {
    const {
        t,
    } = useT()

    return (
        <Layout
            css={classes.rootLayout}
            footer={(
                <Button
                    variant="quinary"
                    onClick={onSubmit}
                >
                    {t('SCAN_ANOTHER')}
                </Button>
            )}
        >
            <div css={classes.root}>
                <div css={classes.logoContainer}>
                    <img
                        alt="Summary"
                        src={SummarySvg}
                        css={classes.logo}
                    />
                </div>
                <div css={classes.form}>
                    <div css={classes.label}>{t('GATEWAY')}</div>
                    <div css={classes.labelValue}>{gateway}</div>
                </div>
            </div>
        </Layout>
    )
}

export default Summary
