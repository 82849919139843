import React, {
    useState,
} from 'react'
import Button from '@src/shared-components/Button'
import Layout from '@src/Layout'
import Header from '@src/Layout/components/Header'
import MainPopup from '@src/MainPopup'
import LockSvg from '@src/assets/access-denied.svg'
import Typography from '@src/shared-components/Typography'
import useT from '@src/hooks/useT/useT'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'

import classes from './AccessDenied.style'

type Props = {
    onBackClick: () => void
    handleOnSupportClick: () => void
}

function AccessDenied({
    onBackClick,
    handleOnSupportClick,
}: Props): JSX.Element {
    const {
        t,
    } = useT()
    const {
        state,
    } = useFlowRouter()
    const [
        isPopupOpened,
        showMainPopup,
    ] = useState(false)

    return (
        <Layout
            header={(
                <Header
                    title={t('ACTIONS')}
                    onMenuClick={() => {
                        return showMainPopup((open) => {
                            return !open
                        })
                    }}
                />
            )}
            footer={(
                <>
                    <Button
                        variant="primary"
                        onClick={onBackClick}
                    >
                        {t('TRY_AGAIN_BUTTON_TITLE')}
                    </Button>
                    <Button
                        variant="primary-outlined"
                        onClick={handleOnSupportClick}
                    >
                        {t('SUPPORT')}
                    </Button>
                </>
            )}
        >
            <MainPopup
                isOpen={isPopupOpened}
                onClose={() => {
                    return showMainPopup(false)
                }}
            />

            <div css={classes.root}>
                <center>
                    <img
                        alt="Logo"
                        src={LockSvg}
                        css={classes.logo}
                    />
                </center>
                <Typography
                    css={classes.header}
                    variant="headline5"
                >
                    {t('ACCESS_DENIED_HEADER')}
                </Typography>
                <Typography
                    css={classes.subHeader}
                    variant="subtitle1"
                >
                    {state.email}
                </Typography>
                <Typography
                    css={classes.subHeader}
                    variant="subtitle1"
                >
                    {t('MAC_ADDRESS_LABEL')}
                    &nbsp;
                    {state.gatewayMac}
                </Typography>
                <Typography
                    css={classes.subHeader2}
                    variant="body2"
                >
                    {t('ACCESS_DENIED_SUB_HEADER_1')}
                </Typography>
                <Typography
                    css={classes.subHeaderlast}
                    variant="body2"
                >
                    {t('ACCESS_DENIED_SUB_HEADER_2')}
                </Typography>
            </div>
        </Layout>
    )
}

export default AccessDenied
