import React, {
    useCallback,
} from 'react'
import useField from '@src/hooks/useField'
import Input, {
    IInputProps,
} from './Input'

interface IProps<TFormValues> extends Omit<IInputProps, 'name'> {
    name: keyof TFormValues
}

function FormInput<TFormValues>({
    name, ...rest
}: IProps<TFormValues>): JSX.Element {
    const field = useField({
        name: name as string,
    })

    const handleChange = useCallback(
        (fieldName: string, value: string) => {
            field.context.setFieldValue(fieldName, value)
        },
        [field.context],
    )

    const handleBlur = useCallback(
        (fieldName: string) => {
            field.context.setFieldTouched(fieldName, true)
        },
        [field.context],
    )

    return (
        <Input
            {...rest}
            value={field.value}
            name={name as string}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    )
}

export default FormInput
