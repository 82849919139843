import React, {
    useCallback, useMemo, useState,
} from 'react'

import APP_FLOW_STEPS from '@src/constants/FlowSteps'
import Typography from '@src/shared-components/Typography'
import useT from '@src/hooks/useT/useT'
import macAddressIcon from '@src/assets/Mac_Address.svg'
import Header from '@src/Layout/components/Header'
import Button from '@src/shared-components/Button'
import MainPopup from '@src/MainPopup/MainPopupContainer'
import Progress from '@src/shared-components/Progress'
import Layout from '@src/Layout'
import {
    getProgressOptions,
} from '@src/pages/ScanQR/components/ScanQR'
import openSupport from '@src/utils/jiraHelpDesk'
import consoleService from '@src/services/consoleService'
import Input from '@src/shared-components/Input/Input'

import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'
import classes from './ManualMacAddress.style'

export type FormProps = {
    macAddress: string
}

function ManualMacAddress(): JSX.Element {
    const {
        t,
    } = useT()
    const {
        updateState,
    } = useFlowRouter()
    const progressOptions = useMemo(() => {
        return getProgressOptions(t)
    }, [t])
    const [
        isPopupOpened,
        setIsPopupOpened,
    ] = useState(false)
    const [
        inputValue,
        setInputValue,
    ] = useState('')

    const handleOnSupportClick = useCallback(() => {
        return openSupport((): void => {
            consoleService.error('Jira extension failed to load.')
        })
    }, [])

    const handleManualInput = useCallback((_name: string, value: string) => {
        setInputValue(value)
    }, [])

    const isValid = inputValue.length > 0

    const handleNextClick = useCallback(() => {
        updateState((prev) => {
            return {
                ...prev,
                gatewayMac: inputValue,
            }
        }, APP_FLOW_STEPS.enterEmail)
    }, [
        inputValue,
        updateState,
    ])

    return (
        <Layout
            header={(
                <Header
                    title={t('INSTALL_GATEWAY')}
                    onMenuClick={() => {
                        return setIsPopupOpened((open) => {
                            return !open
                        })
                    }}
                />
            )}
            footer={(
                <>
                    <div css={classes.input}>
                        <Input
                            name="macAddress"
                            value={inputValue}
                            label={t('MAC_ADDRESS_LABEL')}
                            onChange={handleManualInput}
                        />
                    </div>
                    <Button
                        variant="primary"
                        onClick={handleNextClick}
                        disabled={!isValid}
                    >
                        {t('NEXT')}
                    </Button>
                    <Button
                        variant="primary-outlined"
                        onClick={handleOnSupportClick}
                    >
                        {t('SUPPORT')}
                    </Button>
                </>
            )}
        >
            <div css={classes.root}>
                <MainPopup
                    isOpen={isPopupOpened}
                    onClose={() => {
                        return setIsPopupOpened(false)
                    }}
                />
                <div css={classes.progress}>
                    <Progress
                        options={progressOptions}
                        currentStep={0}
                    />
                </div>
                <center css={classes.header}>
                    <Typography variant="headline5">
                        {t('MAC_ADDRESS_MANUALLY_HEADER')}
                    </Typography>
                </center>
                <div
                    css={classes.previewImg}
                    style={{
                        backgroundImage: `url(${macAddressIcon}`,
                    }}
                />
            </div>
        </Layout>
    )
}

export default ManualMacAddress
