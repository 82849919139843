import {
    css,
} from '@emotion/react'

const classes = {
    root: css`
        display: flex;
        flex-grow: 1;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 24px;
        text-align: center;
    `,
    logo: css`
        width: 100%;
        height: 100%;
    `,
    header: css`
        margin-top: 47px;
    `,
    subHeader: css`
        margin-top: 4px;
    `,
    subHeader2: css`
        margin-top: 16px;
    `,
    subHeaderlast: css`
        margin-top: 0;
    `,
}

export default classes
