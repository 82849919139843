import {
    UseMutationResult, useMutation,
} from '@tanstack/react-query'
import axios from 'axios'

import API_URLS from '@src/constants/ApiUrls'
import useGetAxiosConfig from '@src/api/hooks/useGetAxiosConfig'

type Response = {
    data: {
        flowInformation: boolean,
    }
}

const useCheckInstallationPossible = (
    onSuccess: (flowInformation: boolean)=>void,
): UseMutationResult<
    unknown, unknown, { gatewayImeiMac: string; email: string }, unknown> => {
    const config = useGetAxiosConfig()

    const parserResponseSuccess = (response: Response) => {
        onSuccess(response.data.flowInformation)
    }

    return useMutation(
        {
            mutationFn: ({
                email,
                gatewayImeiMac,
            }) => {
                return axios
                    .get(
                        API_URLS.checkInstallationPossible(gatewayImeiMac, email),
                        config,
                    )
            },
            mutationKey: ['useCheckInstallationPossible'],
            onSuccess: parserResponseSuccess,
        },
    )
}

export default useCheckInstallationPossible
