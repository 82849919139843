/* eslint-disable object-curly-newline */
import {
    AxiosRequestConfig,
} from 'axios'

import ENV from '@src/constants/Env'

const useGetAxiosConfig = (): AxiosRequestConfig => {
    return {
        headers: {
            APIKEY: ENV.REACT_APP_SKYGATE_AUTH_KEY,
        },
    }
}

export default useGetAxiosConfig
