import React, {
    useMemo,
} from 'react'
import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom'
import MainPage from '../../pages/Main'
import APP_URLS from '../../constants/AppUrls'
import GatewayReceivedByUrl from '../../pages/GatewayDetected/GatewayRecievedByUrl'
import RedirectPage from './RedirectPage'

type CustomRoute = {
    path: string;
    element: JSX.Element,
    requireAcceptedTerms?: boolean
}

const getRoutes = (): CustomRoute[] => {
    return [
        {
            path: APP_URLS.scanQR,
            element: <MainPage />,
            requireAcceptedTerms: true,
        },
        {
            path: '/',
            element: (
                <Navigate
                    to={APP_URLS.scanQR}
                    replace
                />
            ),
        },
        {
            path: APP_URLS.gatewayDetectedWithGateway,
            element: <GatewayReceivedByUrl />,
            requireAcceptedTerms: true,
        },
    ]
}

function RenderRoutes() {
    const routes = useMemo(() => {
        return getRoutes()
    }, [])

    return (
        <Routes>
            <Route
                path="redirect"
                element={<RedirectPage />}
            />
            {routes.map((route) => {
                return (
                    <Route
                        key={route.path}
                        element={route.element}
                        path={route.path}
                    />
                )
            })}
            <Route
                path="*"
                element={<MainPage />}
            />
        </Routes>
    )
}

export default RenderRoutes
