import React, {
    useCallback,
    useState,
} from 'react'

import Form from '@src/shared-components/Form'
import Typography from '@src/shared-components/Typography'
import useT from '@src/hooks/useT/useT'
import InstallGatewayLayout from '@src/shared-components/InstallGatewayLayout'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'
import APP_FLOW_STEPS from '@src/constants/FlowSteps'
import UnsavedChangesPrompt from '@src/shared-components/UnsavedChangesPrompt'
import useForm from '@src/hooks/useForm'
import FormInput from '@src/shared-components/Input/FormInput'
import FormError from '@src/shared-components/FormError'
import useCheckInstallationPossible from '@src/hooks/useCheckInstallationPossible'
import ErrorWithSupportPage from '@src/pages/ScanQRError/ErrorWithSupportPage'
import Loading from '@src/shared-components/Loading'
import consoleService from '@src/services/consoleService'

import classes from './EnterEmail.style'

export type FormProps = {
    email: string
}

function EnterEmail(): JSX.Element {
    const {
        t,
    } = useT()
    const [
        showModal,
        setShowModal,
    ] = useState<boolean>(false)
    const [
        showErrors,
        setShowErrors,
    ] = useState(false)
    const {
        updateState,
        state,
        goToStep,
        setIsEdited,
    } = useFlowRouter()

    const form = useForm<FormProps>({
        initialValues: {
            email: state.email ?? '',
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            consoleService.log('submit', values)
        },
    })

    const doBackClick = useCallback(() => {
        updateState((prev) => {
            return {
                ...prev,
                email: undefined,
                allowedToInstall: false,
            }
        }, APP_FLOW_STEPS.scanQR)
    }, [updateState])

    const handleCheckInstallationPossibleSuccess = useCallback((allowed: boolean) => {
        setIsEdited(true)
        updateState((prev) => {
            return {
                ...prev,
                email: form.values.email,
                allowedToInstall: allowed,

            }
        }, allowed ? APP_FLOW_STEPS.installGateway : APP_FLOW_STEPS.accessDenied)
    }, [
        form.values.email,
        updateState,
        setIsEdited,
    ])

    const {
        mutate: checkInstallationPossible,
        isPending: checkInstallationPossiblePending,
        error: checkInstallationPossibleError,
        reset: resetCheckInstallationPossibleError,
    } = useCheckInstallationPossible(handleCheckInstallationPossibleSuccess)

    const handleModalClose = useCallback((positive: boolean) => {
        if (positive) {
            doBackClick()
        } else { setShowModal(false) }
    }, [doBackClick])

    const handleNextClick = useCallback(() => {
        if (form.values.email && state.gatewayMac) {
            checkInstallationPossible({
                gatewayImeiMac: state.gatewayMac,
                email: form.values.email,
            })
        } else {
            setShowErrors(true)
        }
    }, [
        checkInstallationPossible,
        form.values.email,
        state.gatewayMac,
    ])

    const onBackClick = useCallback(() => {
        goToStep(APP_FLOW_STEPS.scanQR)
    }, [goToStep])

    const renderFormError = () => {
        return <FormError>{t('THIS_FIELD_IS_REQUIRED')}</FormError>
    }

    const onErrorBack = useCallback(() => {
        resetCheckInstallationPossibleError()
    }, [resetCheckInstallationPossibleError])

    if (checkInstallationPossibleError) {
        return (
            <ErrorWithSupportPage
                message={t('GENERAL_ERROR_BODY')}
                onBackClick={onErrorBack}
            />
        )
    }

    return (
        <Loading isLoading={checkInstallationPossiblePending}>
            <Form
                form={form}
                css={classes.form}
            >
                <InstallGatewayLayout
                    onBackClick={onBackClick}
                    onNextClick={handleNextClick}
                    nextButtonText={t('CONFIRM')}
                >
                    <center css={classes.header}>
                        <Typography variant="headline5">
                            {t('ENTER_EMAIL')}
                        </Typography>
                    </center>
                    <center css={classes.header}>
                        <Typography
                            variant="subtitle1"
                            css={classes.subHeader}
                        >
                            {t('ENTER_EMAIL_TEXT')}
                        </Typography>
                    </center>
                    <div css={classes.formContent}>
                        <FormInput<FormProps>
                            name="email"
                            label={t('EMAIL')}
                            helperText={showErrors && !form.values.email && renderFormError()}
                        />
                    </div>
                </InstallGatewayLayout>
            </Form>
            <UnsavedChangesPrompt
                open={showModal}
                handleClose={handleModalClose}
            />
        </Loading>

    )
}

export default EnterEmail
