import React, {
    useEffect,
} from 'react'
import {
    useTranslation,
} from 'react-i18next'

import RenderRoutes from './shared-components/RenderRoutes/RenderRoutes'

function App() {
    const {
        i18n,
    } = useTranslation()

    useEffect(() => {
        if (i18n.isInitialized) {
            const savedLanguage = localStorage.getItem('LANGUAGE')
            const appLanguage = savedLanguage ?? navigator.language ?? 'en'

            i18n.changeLanguage(appLanguage.toLowerCase())
        }
    }, [i18n])

    return <RenderRoutes />
}

export default App
