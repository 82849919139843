import {
    css,
} from '@emotion/react'

const classes = {
    previewImg: css`
        display: flex;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: auto;
        flex-grow: 1;
        margin: 20px;
        height: 300px;
    `,
    header: css`
        padding: 20px;
        padding-bottom: 0;
    `,
    subHeader: css`

    `,
    form: css`
        height: 100%;
        min-height: 100%;
    `,
    formContent: css`
        display: grid;
        grid-row-gap: 12px;
        padding: 16px 32px;
    `,
}

export default classes
