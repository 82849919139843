import React, {
    useCallback,
    useState,
} from 'react'
import consoleService from '@src/services/consoleService'
import openSupport
    from '@src/utils/jiraHelpDesk'
import queryClient
    from '@src/services/queryClient'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'
import UnsavedChangesPrompt from '@src/shared-components/UnsavedChangesPrompt'
import usePhotoContext from '@src/context/PhotosContext/usePhotoContext'
import APP_FLOW_STEPS from '@src/constants/FlowSteps'

import MainPopup from './components/MainPopup'

type Props = {
    isOpen: boolean
    onClose?: () => void
}

function MainPopupContainer({
    isOpen, onClose,
}: Props): JSX.Element {
    const {
        updateState,
        isEdited,
        setIsEdited,
        goToStep,
    } = useFlowRouter()

    const [
        showModal,
        setShowModal,
    ] = useState(false)
    const {
        cleanUp: cleanUpSavedPhotos,
    } = usePhotoContext()

    const hideModal = useCallback(() => {
        setShowModal(false)
    }, [setShowModal])

    const doInstall = useCallback(() => {
        updateState(() => {
            return {}
        })
        queryClient.resetQueries()
        if (onClose) {
            onClose()
        }
        goToStep(APP_FLOW_STEPS.scanQR)
    }, [
        onClose,
        updateState,
        goToStep,
    ])

    const handleInstallClick = useCallback(() => {
        if (isEdited) {
            setShowModal(true)
        } else {
            hideModal()
            doInstall()
        }
    }, [
        doInstall,
        hideModal,
        isEdited,
    ])

    const jiraHelpDeskCallback = useCallback(() => {
        return openSupport((): void => {
            consoleService.error('Jira extension failed to load.')
        })
    }, [])

    const handleSupportClick = useCallback(() => {
        jiraHelpDeskCallback()
        if (onClose) {
            onClose()
        }
    }, [
        jiraHelpDeskCallback,
        onClose,
    ])

    const handleModalClose = useCallback((positive: boolean) => {
        if (positive) {
            cleanUpSavedPhotos()
            setIsEdited(false)
            doInstall()
        } else {
            hideModal()
        }
    }, [
        setIsEdited,
        doInstall,
        hideModal,
        cleanUpSavedPhotos,
    ])

    return (
        <>
            <MainPopup
                isOpen={isOpen}
                onClose={onClose}
                onInstallClick={handleInstallClick}
                onSupportClick={handleSupportClick}
            />
            <UnsavedChangesPrompt
                open={showModal}
                handleClose={handleModalClose}
            />
        </>
    )
}

export default MainPopupContainer
